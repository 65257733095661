import { MantineProvider } from '@mantine/core';
import { countUpInvitationLinkClick } from 'app/api/user';
import { removeFromCart } from 'app/store/cartSlice';
import { setReferrerCode } from 'app/store/persistorSlice';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userDataPending } from '../../app/store/authSlice';
import { default as CookiesService } from '../../services/CookiesService';
import ModalProvider from '../Atoms/modals/ModalProvider';
import Footer from '../Footer';
import MainHeader from '../Header';
import ErrorBoundary from '../error/ErrorBoundary';

const CompareBtn = dynamic(() => import('../comparator/CompareBtn'));
const CookieModal = dynamic(() => import('../Atoms/modals/CookieModal'));
const RouterTransition = dynamic(() => import('../Atoms/RouterTransition'), {
  ssr: false,
});

function Layouts({ children }) {
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector((state) => state.auth.isUserLoggedIn);
  const userData = useSelector((state) => state.auth.userData);
  const router = useRouter();
  const cartGuestItems = useSelector((state) => state.cart?.cartItems);
  const cookiePermissions = useSelector(
    (state) => state.persistor.cookiePermissions
  );
  const comparisonBikes = useSelector((state) => state.comparison.bikes);

  useEffect(() => {
    if (isUserLoggedIn) {
      dispatch(userDataPending());
    } else {
      // dispatch(refreshTokenPending());
    }
  }, [dispatch, isUserLoggedIn]);

  useEffect(() => {
    if (
      isUserLoggedIn &&
      userData &&
      process.env.NODE_ENV === 'production' &&
      cookiePermissions.upScope
    ) {
      console.log(1);
      /* If the user is logged in, optionally identify them with the following method. */
      /* You can call Upscope('updateConnection', {}); at any time. */
      Upscope('updateConnection', {
        /* Set the user ID below. If you don't have one, set to undefined. */
        uniqueId: userData.id || undefined,

        /* Set the user name or email below (e.g. ["John Smith", "john.smith@acme.com"]). */
        identities: [userData.name + ' ' + userData.last_name, userData.email],
      });
    }
  }, [isUserLoggedIn, userData]);

  useEffect(() => {
    if (router.locale) {
      CookiesService.set('NEXT_LOCALE_CODE', router.locale, {
        maxAge: 1000 * 60 * 60 * 24 * 30,
        path: '/',
      });
      if (cartGuestItems?.length > 0)
        cartGuestItems?.forEach((item) => {
          if (
            !item.locale ||
            (!item.category_id && !item.server?.category_id)
          ) {
            dispatch(removeFromCart({ ...item, isCleaning: true }));
          }
        });
    }
  }, [router.locale]);

  /**
   * useEffect hook that runs when the component mounts or when `router.query` changes.
   * It sets the `referrer` variable from `router.query.referrer` and dispatches a `setReferrerCode` action with the `referrer` value.
   * It also calls the `countUpInvitationLinkClick` function with the `referrer` value.
   */
  useEffect(() => {
    // Get the `referrer` value from `router.query.referrer`
    const referrer = router.query.referrer;

    if (referrer) {
      // Call the `countUpInvitationLinkClick` function with the `referrer` value
      (async () => {
        try {
          await countUpInvitationLinkClick(referrer);
          dispatch(setReferrerCode(referrer));
        } catch (error) {
          // Handle any errors that occur during the function call
          console.log(error);
        }
      })();
    }
  }, [router.query.referrer]);

  return (
    <>
      <ErrorBoundary>
        <MantineProvider>
          <ModalProvider>
            <MainHeader />
            {children}
            <Footer />
            <RouterTransition />
            {comparisonBikes.length > 0 && <CompareBtn />}
            <CookieModal />
          </ModalProvider>
        </MantineProvider>
      </ErrorBoundary>
    </>
  );
}

export default Layouts;
